@import '../../../../../../../global/fonts.scss';

.template-preview-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 320px;
  border-radius: 12px;
  background: #FFF;
  margin-bottom: 16px;

  h1 {
    color: #000;
    font-style: normal;
    line-height: 24px;
    @include OpenSansFont(600, 20px, 14px);
    margin-bottom: 16px;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }
}

.template-preview-header {
  display: flex;
  padding: 16px 16px 4px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  margin-bottom: 4px;

  .template-type-label {
    @include OpenSansFont(600, 16px, 11px);
    font-style: normal;
    color: #8c8c8c;
  }
}

.template-content-body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    gap: 16px;
    flex-wrap: wrap;
    padding: 0 16px 16px 16px;
}

.template-widget-container {
  display: flex;
  width: 100%;
  height: 32px;
  padding: 4px 8px;
  align-items: center;
  gap: 24px;
  border-radius: 8px;
  background: #F0F0F0;
}

.widget-title-label {
  @include OpenSansFont(600, 16px, 14px);
  font-style: normal;
  color: #000;
}

.widget-icon {
  background-image: url('../../../../../../../assets/icons/general/widget-icon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
}

.template-paragraph-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid #F0F0F0;
  padding: 6px 8px;
}

.paragraph-texts-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 218px;
  gap: 4px;
}

.paragraph-title-label {
  @include OpenSansFont(600, 16px, 14px);
  font-style: normal;
  color: #000;
}

.paragraph-description-label {
  @include OpenSansFont(400, 16px, 14px);
  font-style: normal;
  color: #8c8c8c;
}

.paragraph-icon-container {
  display: flex;
  width: 16px;
  height: 16px;
  padding: 3px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 2px;
  border: 1px solid #4181FB;
}

.paragraph-icon {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  top: 6px;
  left: 0.5px;
  width: 22px;
  height: 22px;
  background-image: url('../../../../../../../assets/icons/general/paragraph-icon.svg');
}

