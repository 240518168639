@import '../../../../global/fonts.scss';

.datepicker-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.datepicker-label-container {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.article-generation-date-picker {
  width: 232px;
  display: flex;
  height: 38px;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  background: #FFF;

  /* New styles for text */
  color: #000;
  @include OpenSansFont(400, 20px, 14px);
  font-style: normal;

}

.article-generation-advanced-search-date-picker {
  min-width: 374px;
  width: 100%;
  max-width: 100%;
  display: flex;
  height: 38px;
  padding: 8px 12px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  border: 1px solid #D9D9D9;
  background: #FFF;

  /* New styles for text */
  color: #000;
  @include OpenSansFont(400, 20px, 14px);
  font-style: normal;

}

.article-generation-date-picker input {
  color: #000;
  @include OpenSansFont(400, 20px, 14px);
  font-style: normal;  font-size: 14px;
  line-height: 20px;
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
}

.calendar-icon {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background-image: url('../../../../assets/icons/general/calendar-icon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 16px;
  height: 16px;
  pointer-events: none;
}

.info-icon {
  background-image: url('../../../../assets/icons/general/info.svg');
  background-repeat: no-repeat;
  background-size: contain;
  width: 14px;
  height: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: invert(44%) sepia(97%) saturate(5732%) hue-rotate(214deg) brightness(99%) contrast(97%);
}

.info-message {
  color: #000;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  z-index: 1;
  bottom: 24px;
  padding: 8px;
  justify-content: space-between;
  align-self: stretch;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid  #F0F0F0;
  background: #FAFAFA;
  display: flex;
  width: 274px;
  flex-direction: column;
  align-items: flex-start;

  .info-message-title {
    display: flex;
    align-items: center;
    @include OpenSansFont(600, 20px, 14px);
    border-bottom: 1px solid #F0F0F0;
    min-height: 36px;
    padding: 8px;
    justify-content: space-between;
    align-self: stretch;
  }

  .info-close-icon {
    width: 16px;
    height: 16px;
    background-image: url('../../../../assets/icons/general/close.svg');
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .info-message-description {
    @include OpenSansFont(400, 20px, 12px);
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
  }
}

