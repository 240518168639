@import '../../../../global/fonts.scss';

$gap: 16px;

.article-generation-general-layout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  gap: $gap;
}

#article-ai-generation-wrapper {
  margin-bottom: 16px;
  border-radius: 0 16px 16px 16px;
  background: #FFF;
  box-shadow: 0 0 50px 10px rgba(37, 40, 49, 0.06);
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  align-items: flex-start;
  gap: $gap;
  width: 100%;
  max-width: 791px;

  h1 {
    color: #000;
    font-style: normal;
    line-height: 24px;
    @include OpenSansFont(600, 24px, 16px);
    margin-bottom: $gap;
    pointer-events: none;
  }
}

@media screen and (max-width: 768px) {
  #article-ai-generation-wrapper {
    width: 100%;
  }
}


#article-ai-schedules-listing-wrapper {
  margin-bottom: 16px;
  border-radius: 0 16px 16px 16px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h1 {
    color: #000;
    font-style: normal;
    line-height: 24px;
    @include OpenSansFont(600, 24px, 16px);
    margin-bottom: $gap;
    pointer-events: none;
  }
}

.header-description-label {
  color: #000;
  @include OpenSansFont(400, 20px, 14px);
  font-style: normal;
  pointer-events: none;
}

.input-fields-group {
  gap: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
}

.generic-field-labels-container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}

.input-fields-label {
  color: #0F172A;
  @include OpenSansFont();
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  pointer-events: none;
}

.optional-label {
  color:  #8C8C8C;
  @include OpenSansFont();
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  pointer-events: none;
}

// Listing header styles
.article-generation-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 24px;
}

.schedules-listing-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.article-generation-schedules-listing-header {
  display: flex;
  padding: 24px 16px;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
  width: 100%;
}

.title-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  max-width: 50%;
}

// Listing search styles
.schedules-listing-search-container {
  display: flex;
  gap: 8px;
  width: 364px;
  align-items: center;
}

.schedules-listing-search-bar-input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.schedules-listing-search-bar-wrapper {
  position: relative;
  display: flex;
  width: 332px;
}

.schedules-listing-search-bar {
  display: flex;
  padding: 8px 12px;
  padding-left: 30px;
  align-items: center;
  width: 100%;
  border-radius: 4px 0 0 4px;
  border-top: 1px solid #D9D9D9;
  border-bottom: 1px solid #D9D9D9;
  border-left: 1px solid #D9D9D9;
  border-right: none;
  max-width: 260px;
  color: #BFBFBF;
  font-style: normal;
  height: 36px;
  @include OpenSansFont(400, 20px, 12px);
}

.search-icon {
  position: absolute;
  width: 16px;
  height: 16px;
  background-image: url('../../../../assets/icons/general/search-icon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
  mix-blend-mode: luminosity;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
}

.filters-icon {
  width: 24px;
  height: 24px;
  background-image: url('../../../../assets/icons/general/filter.svg');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.schedules-search-button {
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 0 4px 4px 0;
  background: #1262FA;
  cursor: pointer;
}

.schedules-search-button-label {
  color: #FFF;
  font-style: normal;
   @include OpenSansFont(600, 20px, 12px);
  pointer-events: none;
}

// Table styles
.article-generation-schedules-listing-table-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.content-list-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

.article-generation-schedules-listing-table-header  {
  display: flex;
  padding: 6px 16px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top: 1px solid  #F5F5F5;
  border-bottom: 1px solid  #F5F5F5;
  background: #FFF;
  overflow: hidden;
  width: 100%;
  flex-wrap: nowrap;
  min-width: 0;
  text-align: left;
}

.article-generation-schedules-listing-table-row   {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  align-self: stretch;
  width: 100%;
  flex-wrap: nowrap;
  min-width: 0;
  overflow: hidden;
}

.article-generation-schedules-listing-table-header-label {
  color:  #000;
  font-style: normal;
  @include OpenSansFont(600, 20px, 12px);
}

.row-delete-icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  background-image: url('../../../../assets/icons/general/delete-icon.svg');
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.schedule-event-name-cell {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;

  .schedule-event-name {
    color: #000;
    @include OpenSansFont(400, 20px, 12px);
    font-style: normal;
  }

  .schedule-competition-name {
    color: #8C8C8C;
    @include OpenSansFont(400, 20px, 12px);
    font-style: normal;
  }
}

.schedule-row-label {
  color: #000;
  @include OpenSansFont(400, 20px, 12px);
  font-style: normal;
}

.status-container {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-self: stretch;
  align-items: center;
}

.status-icon {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: cover;
}

.table-actions-container {
  text-align: center;
}

.delete-icon-container {
  cursor: pointer;
  width: 32px;
  height: 32px;
  gap: 0;
  border-radius: 4px;
  padding: 8px;
  display: inline-block;
}

.delete-icon {
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  background-size: cover;
}

.table-data {
  text-align: left;
}

// Advanced filters
.advanced-filters-container {
  display: flex;
  width: 100%;
  padding: 16px;
  flex-direction: column;
  gap: 16px;
}

.advanced-filters-row {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  flex-wrap: wrap;
  width: 100%;
}

.advanced-filters-buttons-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.clear-filters-button {
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid  #D92033;
  cursor: pointer;
}

.clear-filters-button-label {
  color:  #D92033;
  font-style: normal;
  @include OpenSansFont(600, 20px, 12px);
  pointer-events: none;
}

.advanced-search-button {
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  background:  #1262FA;
  cursor: pointer;
}

.advanced-search-button-label {
  color:  #FFFFFF;
  font-style: normal;
  @include OpenSansFont(600, 20px, 12px);
  pointer-events: none;
}


// Pagination
.pagination-container {
  display: flex;
  padding: 12px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}

.schedules-listing-pagination {
  @include OpenSansFont(400, 20px, 12px);
  padding-left: 0;
  margin-top: 2%;

  // General
  .active {
    a {
      color: #fff;
      cursor: default;
      background-color: #1262FA;
      border: 1px solid #D9D9D9FF;
    }
  }

  .next {
    a {
      border-radius: 0 4px 4px 0;
      border-left: none;
    }
  }

  .previous {
    a {
      border-radius: 4px 0 0 4px;
      border-right: none;
    }
  }

  li {
    display: inline-block;
    text-align: -webkit-match-parent;

    a {
      position: relative;
      float: left;
      padding: 6px 12px;
      line-height: 1.5;
      color: #000000FF;
      text-decoration: none;
      background-color: #fff;
      border: 1px solid #D9D9D9FF;
    }
  }


  [role='button'] {
    cursor: pointer;
  }
}

// Delete Modal
.delete-schedule-modal {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  position: absolute;
  top: 25%;
  left: 40%;
  transform: translate(-50%, -50%);
}

.delete-schedule-modal-text-header {
  @include OpenSansFont(600, 28px, 20px);
  text-align: left;
  color: #0F172AFF;
}

.delete-schedule-modal-text {
  @include OpenSansFont(400, 20px, 14px);
  text-align: left;
}

.delete-schedule-modal-title-text {
  @include OpenSansFont(700, 20px, 14px);
  text-align: left;
}

.delete-schedule-modal-btns {
  gap: 24px;
  display: flex;
  align-items: flex-end;
}

.cancel-delete-btn {
  display: flex;
  height: 38px;
  padding: 4px  24px;
  align-items: center;
  gap:  4px;
  border-radius: 6px;
  border: 1px solid #000;
  cursor: pointer;
}

.cancel-delete-btn-text {
  @include OpenSansFont(700, 20px, 14px);
  color:  #000;
  pointer-events: none;
}

.confirm-delete-btn {
  display: flex;
  height: 38px;
  padding: 4px 24px;
  align-items: center;
  gap:  4px;
  border-radius: 6px;
  background:  #E25160;
  cursor: pointer;
}

.confirm-delete-btn-text {
  @include OpenSansFont(700, 20px, 14px);
  text-align: left;
  color: #FFF;
  pointer-events: none;
}

// Input fields
.input-fields-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  align-self: stretch;
  gap: 16px;
  margin-bottom: 24px;
  flex-wrap: wrap;
}

.custom-info-panel {
  width: 100%;
  display: flex;
  height: 36px;
  padding: 8px 12px;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  background:  #D0E0FE;
  gap: 8px;
  margin-bottom: 24px;

  .info-panel-label {
    color:  #434343;
    @include OpenSansFont();
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

}

.info-icon {
  background-image: url('../../../../assets/icons/general/info.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 20px;
  height: 20px;
  filter: invert(44%) sepia(97%) saturate(5732%) hue-rotate(214deg) brightness(99%) contrast(97%);
}

.schedule-button-container {
  display: flex;
  padding: 16px 16px 0 0;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 0 0 12px 12px;
  border-top: 1px solid  #F0F0F0;
  background:  #FFF;

  .schedule-button {
    display: flex;
    padding: 8px 16px;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background:  #1262FA;


    .schedule-button-label {
      color:  #FFF;
      @include OpenSansFont();
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
    }
  }
}


#article-ai-generation-tabs-menu {
  display: flex;
  align-items: center;
  gap: 1px;


  .tab-option {
    display: flex;
    padding: 16px 24px;
    align-items: flex-start;
    gap: 24px;
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid;
    border-bottom-color: #F0F0F0;
    background:  #FAFAFA;
    cursor: pointer;

    &.selected-tab {
      background: #FFF;
      border-bottom: none;

      .tab-label {
        color: #000;
        @include OpenSansFont(600, 20px, 14px);
        font-style: normal;
      }

    }

    .tab-label {
      color: #000;
      @include OpenSansFont(400, 20px, 14px);
      font-style: normal;
    }
  }
}

.tab-option-container {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}

.schedules-bubble-count {
  display: flex;
  width: 20px;
  height: 20px;
  padding: 3px 6px 2px 6px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 44px;
  background: #71A1FC;
}

.schedules-count-label {
  color: #FFF;
  text-align: center;
  @include OpenSansFont(600, 16px, 11px);
  font-style: normal;
}

.custom-team-select {
  width: 242px;

  @include OpenSansFont(400, 20px, 14px);

  img {
    width: 24px;
    height: 24px;
  }

  .option-container {
    gap: 8px;
    padding: 0;
  }

  .option-container .option-logo-team {
    margin-right: 0;
  }

  .option-container-dropdown {
    cursor: pointer;
    height: 52px;
    font-size: 14px;
    padding: 8px 16px;

    &:hover {
      background-color: #2584ff;
      color: white;

      span,label {
        color: white;
      }
    }

    .option-label-additional {
      @include OpenSansFont(400, 20px, 12px);
      color: #8C8C8C;
      font-style: normal;
      white-space: nowrap;

    }
  }
}

.custom-event-select {
  width: 500px;

  @include OpenSansFont(400, 20px, 14px);

  .option-container {
    gap: 8px;
    padding: 0;
  }

  .option-container-dropdown {
    cursor: pointer;
    font-size: 14px;
    width: 501px;
    padding: 8px 16px;
    max-width: 100%;

    &:hover {
      background-color: #2584ff;
      color: white;

      span,label {
        color: white;
      }
    }

    .option-label-additional {
      @include OpenSansFont(400, 20px, 12px);
      color: #8C8C8C;
      font-style: normal;
    }
  }
}

.event-custom-option-label {
  display: flex;
  gap: 8px;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;


  .option-label-additional {
    @include OpenSansFont(400, 20px, 12px);
    color: #8C8C8C;
    font-style: normal;
    white-space: nowrap;
    flex-grow: 1
  }
}

.error-container {
  display: flex;
  flex-direction: row;
  gap: 4px;

  .error-icon {
    background-image: url('../../../../assets/icons/general/error-icon-info.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 16px;
    height: 16px;
    pointer-events: none;
    margin-top: 3px;
  }
}

.sport-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.option-label-additional {
  display: flex;
  align-items: center;
  gap: 4px;
}












